import {
  Mail,
  Phone,
  MapPin,
  Facebook,
  Twitter,
  Youtube,
  Instagram,
} from "lucide-react";
import Container from "./Container";
import { Link } from "react-router-dom";

const TopHeader = () => {
  return (
    <div className="bg-yellow-100 flex flex-col md:flex-row justify-between items-center p-4 pb-11">
      <Container className="flex flex-col md:flex-row justify-between items-center">
        <div className="flex flex-col md:flex-row lg:space-x-8">
          <div className="flex items-center space-x-2">
            <div className="hover:scale-110 transition-all duration-300 transform bg-yellow-500 p-1 rounded-lg group">
              <Mail className=" text-black group-hover:text-white transition-all duration-300" />
            </div>
            <div>
              <h4 className="font-bold text-base">Email</h4>
              <Link
                target="_blank"
                to="mailto:info@ijhinternational.com"
                className="text-sm text-gray-500"
              >
                info@ijhinternational.com
              </Link>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <div className="hover:scale-110 transition-all duration-300 transform bg-yellow-500 p-1 rounded-lg group">
              <Phone className=" text-black group-hover:text-white transition-all duration-300" />
            </div>
            <div>
              <h4 className="font-bold text-base">Call Us</h4>
              <Link
                target="_blank"
                to="tel:+88 02226616146"
                className="text-sm text-gray-500"
              >
                +88 02226616146
              </Link>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <div className="hover:scale-110 transition-all duration-300 transform bg-yellow-500 p-1 rounded-lg group">
              <MapPin className=" text-black group-hover:text-white transition-all duration-300" />
            </div>
            <div>
              <h4 className="font-bold text-base">Location</h4>
              <Link
                target="_blank"
                to="https://maps.app.goo.gl/KSU34cbUXKPyLXqZ7"
                className="text-sm text-gray-500"
              >
                CNS Kazi Garden,Floor-7/A,KA-150/A,Kuril,Dhaka
              </Link>
            </div>
          </div>
        </div>
        <div className="flex gap-4 mt-4 md:mt-0 lg:space-x-4">
          <Link
            target="_blank"
            to="https://www.facebook.com/profile.php?id=61567976742505&mibextid=ZbWKwL"
            className="cursor-pointer hover:scale-110 transition-all duration-300 transform bg-yellow-500 p-1 rounded-lg group"
          >
            <Facebook className=" text-black group-hover:text-white transition-all duration-300" />
          </Link>
          <Link
            to="/"
            className="cursor-pointer hover:scale-110 transition-all duration-300 transform bg-yellow-500 p-1 rounded-lg group"
          >
            <Twitter className=" text-black group-hover:text-white transition-all duration-300" />
          </Link>
          <Link
            to="/"
            className="cursor-pointer hover:scale-110 transition-all duration-300 transform bg-yellow-500 p-1 rounded-lg group"
          >
            <Youtube className=" text-black group-hover:text-white transition-all duration-300" />
          </Link>
          <Link
            to="/"
            className="cursor-pointer hover:scale-110 transition-all duration-300 transform bg-yellow-500 p-1 rounded-lg group"
          >
            <Instagram className=" text-black group-hover:text-white transition-all duration-300" />
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default TopHeader;
