import AboutUsSection from "../components/home/AboutUsSection";
import BannerSection from "../components/home/BannerSection";
import ContactUs from "../components/home/ContactUs";
import GlobalNetwork from "../components/home/GlobalNetwork";
import MdMessage from "../components/home/MdMessage";
import OurService from "../components/home/OurService";
import { ReviewSection } from "../components/home/ReviewSection";

const HomePage = () => {
  return (
    <>
      <BannerSection />
      <MdMessage />
      <AboutUsSection />
      <OurService />
      <ReviewSection />
      <GlobalNetwork />
      <ContactUs />
    </>
  );
};

export default HomePage;
