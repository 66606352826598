import { Toaster } from "react-hot-toast";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import { ThemeProvider } from "./components/theme-provider";
import HomePage from "./pages/HomePage";
import TopHeader from "./components/common/TopHeader";
import BottomFotter from "./components/common/BottomFotter";
import StickyBadge from "./components/common/StickyBadge";

function App() {
  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <div className="min-h-screen flex flex-col">
        <StickyBadge />
        <div className="required:lative">
          <TopHeader />
          <div className="header-container w-full z-10">
            <Header />
          </div>
        </div>
        <main className="flex-grow">
          <HomePage />
        </main>
        <Footer />
        <BottomFotter />
        <Toaster />
      </div>
    </ThemeProvider>
  );
}

export default App;
