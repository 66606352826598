import { AnimatedTestimonials } from "../../components/ui/animated-testimonials";
import france1 from "../../assets/images/france-1.jpg";
import france2 from "../../assets/images/france-2.jpg";
import portugal1 from "../../assets/images/portugal-1.jpg";
import portugal2 from "../../assets/images/portugal-2.jpg";
import germany1 from "../../assets/images/german-1.jpg";
import germany2 from "../../assets/images/german-2.jpg";
import germany3 from "../../assets/images/german-3.jpg";
import germany4 from "../../assets/images/german-4.jpg";

export function Testimonial() {
  const testimonials = [
    {
      quote:
        "We had an incredible time with this company! Every detail was taken care of, from transportation to activities. The local insights made the trip feel personal and special. 10/10!",
      name: "Sarah Chen",
      designation: "France",
      src: france1,
    },
    {
      quote:
        "An unforgettable experience! The tour was perfectly organized, with amazing guides and stunning destinations. I felt like I was discovering the heart of the country. Highly recommend!",
      name: "Michael Rodriguez",
      designation: "France",
      src: france2,
    },
    {
      quote:
        "The best travel experience we've ever had! The team was attentive, knowledgeable, and made sure every aspect of our trip was perfect. We'll definitely be back for more.",
      name: "Emily Watson",
      designation: "Portugal",
      src: portugal1,
    },
    {
      quote:
        "An unforgettable experience! The tour was perfectly organized, with amazing guides and stunning destinations. I felt like I was discovering the heart of the country. Highly recommend!",
      name: "James Kim",
      designation: "Portugal",
      src: portugal2,
    },
    {
      quote:
        "The best travel experience we've ever had! The team was attentive, knowledgeable, and made sure every aspect of our trip was perfect. We'll definitely be back for more.",
      name: "Lisa Thompson",
      designation: "Germany",
      src: germany1,
    },
    {
      quote:
        "An unforgettable experience! The tour was perfectly organized, with amazing guides and stunning destinations. I felt like I was discovering the heart of the country. Highly recommend!",
      name: "James Kim",
      designation: "Germany",
      src: germany2,
    },
    {
      quote:
        "A seamless and enjoyable travel experience. The team was professional and friendly, making our trip stress-free and memorable. We highly recommend their services.",
      name: "Mark Johnson",
      designation: "Germany",
      src: germany3,
    },
    {
      quote:
        "A seamless and enjoyable travel experience. The team was professional and friendly, making our trip stress-free and memorable. We highly recommend their services.",
      name: "Tom Smith",
      designation: "Germany",
      src: germany4,
    },
  ];
  return <AnimatedTestimonials testimonials={testimonials} />;
}
