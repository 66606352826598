import { createBrowserRouter, RouteObject } from "react-router-dom";
import NotFoundPage from "../pages/NotFoundPage";
import App from "../App";
const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    errorElement: <NotFoundPage />
  },
];

export const router = createBrowserRouter(routes);
