export const flags = [
  {
    id: 1,
    name: "American",
    image: "/flag/american.png",
  },
  {
    id: 2,
    name: "australia",
    image: "/flag/australia.png",
  },
  {
    id: 4,
    name: "Bahrain",
    image: "/flag/bahrain.png",
  },
  {
    id: 5,
    name: "egypt",
    image: "/flag/egypt.png",
  },
  {
    id: 6,
    name: "Europe",
    image: "/flag/europe.png",
  },
  {
    id: 7,
    name: "India",
    image: "/flag/india.png",
  },
  {
    id: 8,
    name: "japan",
    image: "/flag/japan.png",
  },
  {
    id: 9,
    name: "Canada",
    image: "/flag/canada.png",
  },
  {
    id: 10,
    name: "Kuwait",
    image: "/flag/kuwait.png",
  },
  {
    id: 11,
    name: "Malaysia",
    image: "/flag/malaysia.png",
  },
  {
    id: 12,
    name: "Oman",
    image: "/flag/oman.png",
  },
  {
    id: 13,
    name: "Qatar",
    image: "/flag/qatar.png",
  },
  {
    id: 14,
    name: "Saudi Arabia",
    image: "/flag/saudi-arabia.png",
  },
  {
    id: 15,
    name: "Singapore",
    image: "/flag/singapore.png",
  },
  {
    id: 16,
    name: "South Africa",
    image: "/flag/south-africa.png",
  },
  {
    id: 17,
    name: "Thailand",
    image: "/flag/thailand.png",
  },
  {
    id: 18,
    name: "UAE",
    image: "/flag/united-arab-emirates.png",
  },
];
 