import Container from "../common/Container";
import mdImage from "../../assets/images/md-image.webp";

const MdMessage = () => {
  return (
    <>
      <Container className="py-32">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex-1">
            <img src={mdImage} alt="md" className="object-cover rounded-lg" />
          </div>
          <div className="flex-1 flex flex-col justify-center  gap-4">
            <h2 className="text-6xl md:text-5xl lg:text-6xl font-semibold dark:text-white">Message from MD</h2>
            <p className="text-lg  py-5 dark:text-white/75">
              I am delighted to welcome you to IJH international , your
              international , your ultimate travel partner. As the MD of this
              company , I take great pride in the services offer to our clients.
              At, IJH we understand that travel is not just about reaching a
              destination. it's about experience that comes with-it. That's why
              we strive to provide personalized travel experience that meet the
              unique needs of each of our clients. <br /> <br /> Our team of
              experienced professionals is committed to make in your travel
              dreams come true for booking your flights and accommodation to
              planning your itinerary and activities. We ensure that every
              aspect of your trip is taken care of. We are also highly skilled
              and experienced in visa processing all over the world. Thanking
              you for choosing JHI as your travel partner. we look forward to
              serving you and making your travel dreams a reality.
            </p>
            <div className="gap-4">
              <h3 className="text-2xl font-semibold dark:text-white">
                Jewel Hossain
              </h3>
              <p className="text-base dark:text-white/75">Managing Director</p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default MdMessage;
