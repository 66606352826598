import * as React from "react";
import { Link as ScrollLink } from 'react-scroll';

import { cn } from "../lib/utils";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "../components/ui/navigation-menu";

export function MainNavigationMenu() {
  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <ScrollLink to="/" smooth={true} duration={500}>
            <NavigationMenuLink
              className={cn(navigationMenuTriggerStyle())}
            >
              Home
            </NavigationMenuLink>
          </ScrollLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <ScrollLink to="service" smooth={true} duration={500}>
            <NavigationMenuLink
              className={cn(navigationMenuTriggerStyle())}
            >
              Service
            </NavigationMenuLink>
          </ScrollLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <ScrollLink to="about" smooth={true} duration={500}>
            <NavigationMenuLink
              className={cn(navigationMenuTriggerStyle())}
            >
              About us
            </NavigationMenuLink>
          </ScrollLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <ScrollLink to="reviews" smooth={true} duration={500}>
            <NavigationMenuLink
              className={cn(navigationMenuTriggerStyle())}
            >
              Review
            </NavigationMenuLink>
          </ScrollLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <ScrollLink to="contact" smooth={true} duration={500}>
            <NavigationMenuLink
              className={cn(navigationMenuTriggerStyle())}
            >
              Contact us
            </NavigationMenuLink>
          </ScrollLink>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none poppins-medium text-linkSize">
            {title}
          </div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";
