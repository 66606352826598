import { motion } from "framer-motion";
import { Badge } from "../ui/badge";

export default function StickyBadge({
  text = "Recruiting License Number RL-2518",
}: {
  text?: string;
}) {
  return (
    <motion.div
      className="fixed bottom-12 left-4 z-50 animate-bounce"
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
    >
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <Badge 
          variant="default"
          className="px-3 py-1 text-sm font-medium bg-[#002D5B] hover:bg-[#002D5B]/70 transition-colors duration-200 dark:bg-[#002D5B] dark:hover:bg-[#002D5B]/80 cursor-pointer"
        >
          {text}
        </Badge>
      </motion.div>
    </motion.div>
  )
}