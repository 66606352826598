import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

interface CustomButtonProps {
  to: string;
  text: string;
  className?: string;
  variant?: 'primary' | 'outline';
}

const CustomButton: React.FC<CustomButtonProps> = ({ to, text, className = '', variant = 'primary' }) => {
  const baseClasses = 'inline-block rounded-lg border-2 px-[40px] py-[15px] poppins-medium text-link uppercase tracking-wider transition duration-300 hover:-translate-y-2';
  const primaryClasses = 'bg-primary text-white font-bold border-primary hover:bg-transparent hover:text-white';
  const outlineClasses = 'bg-transparent text-white font-bold border-primary hover:bg-primary hover:text-white';

  const variantClasses = variant === 'outline' ? outlineClasses : primaryClasses;

  return (
    <ScrollLink
      to={to}
      className={`${baseClasses} ${variantClasses} ${className}`}
    >
      {text}
    </ScrollLink>
  );
};

export default CustomButton;
