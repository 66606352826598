import { Moon, Sun } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { useTheme } from "../components/theme-provider";

export function ModeToggle() {
  const { setTheme } = useTheme();

  return (
    <div className="cursor-pointer pr-8">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="relative">
            <Sun className="absolute top-3 h-[1.2rem] w-[1.2rem] text-white rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
            <Moon className="absolute top-3 h-[1.2rem] w-[1.2rem] text-yellow-500 rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={() => setTheme("light")}>
            Light
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setTheme("dark")}>
            Dark
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setTheme("system")}>
            System
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
