import { useState } from "react";
import emailjs from "emailjs-com";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import Container from "../common/Container";
import toast from "react-hot-toast";
import { Loader2 } from "lucide-react";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    emailjs
      .send(
        import.meta.env.VITE_EMAILJS_SERVICE_ID!,
        import.meta.env.VITE_EMAILJS_TEMPLATE_ID!,
        formData,
        "tJdTq4Xx3ba0lptdW" // Your public key
      )
      .then(
        (_result) => {
          toast.success("Message sent successfully!");
          setFormData({ name: "", email: "", subject: "", message: "" });
          setIsLoading(false);
        },
        (_error) => {
          toast.error("Failed to send message, please try again.");
          setIsLoading(false);
        }
      );
  };

  return (
    <div id="contact">
      <Container className="py-10 md:pb-16 mb:pt-4 lg:pb-24">
        <h2 className="py-10 md:py-12 lg:py-16 text-6xl md:text-5xl lg:text-6xl text-center dark:text-white font-bold mb-4">
          Contact Us
        </h2>
        <div className="flex flex-col lg:flex-row gap-8 items-center justify-center">
          <div
            className="flex-1 relative w-full"
            style={{ paddingBottom: "40%" }}
          >
            <iframe
              className="absolute top-0 left-0 w-full h-full"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d456.2512947867928!2d90.4222208!3d23.8182307!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c75faf71dc27%3A0x41eb0efff785ec35!2z4KaV4Ka-4Kac4KeAIOCml-CmvuCmsOCnjeCmoeCnh-CmqCwg4KaV4Ka-4Kac4Ka_4Kas4Ka-4Kaw4KaHIHwg4KaV4KeB4Kah4Ka84Ka_4Kay!5e0!3m2!1sbn!2sbd!4v1731231980576!5m2!1sbn!2sbd"
              style={{ border: "0" }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="w-full lg:w-1/2">
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="name"
                  className="text-base font-medium text-gray-700 dark:text-white"
                >
                  Full Name *
                </label>
                <Input
                  type="text"
                  name="name"
                  required
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-3 w-full dark:bg-transparent dark:text-white"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="text-base font-medium text-gray-700 dark:text-white"
                >
                  Email *
                </label>
                <Input
                  type="email"
                  name="email"
                  required
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-3 w-full dark:bg-transparent dark:text-white"
                />
              </div>
              <div>
                <label
                  htmlFor="subject"
                  className="text-base font-medium text-gray-700 dark:text-white"
                >
                  Subject
                </label>
                <Input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="mt-3 w-full dark:bg-transparent dark:text-white"
                />
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="text-base font-medium text-gray-700 dark:text-white"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  required
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                  className="mt-3 h-40 border-2 border-primary-300 rounded-md p-2 w-full dark:bg-transparent dark:text-white"
                ></textarea>
              </div>
              {isLoading ? (
                <div className="border-2 rounded-lg text-small  py-[8px] px-[40px] border-primary w-fit">
                  <Loader2 className="w-5 h-5 animate-spin" />
                </div>
              ) : (
                <Button
                  type="submit"
                  className="border-2 rounded-lg text-small uppercase tracking-[4px] py-[15px] px-[40px] border-primary text-white hover:bg-primary transition-all duration-300"
                >
                  Submit
                </Button>
              )}
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContactUs;
