import Container from "../common/Container";
import CustomButton from "../common/CustomButton";
import { Facebook, Instagram, Twitter } from "lucide-react";
import { Link } from "react-router-dom";
import arrowIcon from "../../assets/icons/arrow-down.webp";

const BannerSection = () => {
  return (
    <>
      {/* Page Header */}
      <div className="relative">
        {/* Background Image with Overlay */}
        <div className="absolute right-0 left-0 top-0 bottom-0 -z-50 inset-0 bg-banner bg-cover bg-center bg-no-repeat">
          <div className="absolute inset-0 bg-primary opacity-75" />
        </div>

        {/* Content */}
        <Container>
          <div className="pt-28 md:pt-0 md:h-screen flex flex-col md:flex-row justify-center items-center px-4">
            <div className="flex-1">
              <h1 className="text-xl md:text-2xl lg:text-4xl text-[#FEF9C3] font-bold mb-4">
                Recruiting travel & tourism
              </h1>
              <h1 className="text-6xl md:text-5xl lg:text-6xl text-white font-bold mb-4">
                Your journey to Visa Application starts here
              </h1>
              <p className="text-white/90 mb-8 text-lg">
                We provide the guidance and services to make your immigration
                process easy, affordable, and reliable.
              </p>
              <CustomButton to="contact" text="CONTACT US" />
            </div>
            <div className="flex-1 flex flex-col gap-4 py-10 md:py-0">
              <div className="flex justify-end">
                <Link
                  target="_blank"
                  to="https://www.facebook.com/profile.php?id=61567976742505&mibextid=ZbWKwL"
                  className="w-fit bg-white/10 hover:bg-white/20 transition-all duration-300 p-4 rounded-full"
                >
                  <Facebook className="w-6 h-6 text-white" />
                </Link>
              </div>
              <div className="flex justify-end">
                <Link
                  to="/"
                  className="w-fit bg-white/10 hover:bg-white/20 transition-all duration-300 p-4 rounded-full"
                >
                  <Instagram className="w-6 h-6 text-white" />
                </Link>
              </div>
              <div className="flex justify-end">
                <Link
                  to="/"
                  className="w-fit bg-white/10 hover:bg-white/20 transition-all duration-300 p-4 rounded-full"
                >
                  <Twitter className="w-6 h-6 text-white" />
                </Link>
              </div>
              <div className="flex justify-end">
                <div className="aspect-square w-10">
                  <img src={arrowIcon} alt="arrow" />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default BannerSection;
