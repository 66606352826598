import { Facebook, Linkedin, Instagram, Twitter } from "lucide-react";
import { Link } from "react-router-dom";
import Container from "./Container";

export default function Footer() {
  return (
    <footer className="w-full bg-primary py-16">
      <Container>
        <div className="grid lg:grid-cols-2">
          {/* Left Section*/}
          <div className="relative  bg-primary  pb-10 md:pb-0">
            <div className="relative z-10">
              <p className="text-white/90 poppins-regular uppercase tracking-[4px] text-small mb-4">
                READY TO DO THIS
              </p>
              <h2 className="text-5xl lg:text-[75px] poppins-black text-white mb-8 leading-tight">
                Let's get
                <br />
                to work
              </h2>
              <Link
                to="#"
                className="inline-block border-2 rounded-sm text-small uppercase tracking-[4px] py-[15px] px-[40px] poppins-medium border-white text-white hover:bg-white hover:text-primary transition-all duration-300"
                style={{
                  padding: "15px 40px",
                  borderWidth: "2px",
                  fontSize: "inherit",
                  fontWeight: "inherit",
                  transform: "translateY(0)",
                  transition: "transform 0.3s ease, background-color 0.3s ease",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-5px)";
                  e.currentTarget.style.backgroundColor = "white";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.backgroundColor = "transparent";
                }}
              >
                CONTACT US
              </Link>
            </div>
            <div className="absolute right-0 bottom-0 opacity-50">
              <div className="relative w-64 h-64">
                <div className="absolute right-0 bottom-0 w-48 h-48 rounded-full bg-pink-400/30" />
                <div className="absolute right-12 bottom-12 w-48 h-48 rounded-full bg-pink-400/30" />
                <div className="absolute right-24 bottom-24 w-48 h-48 rounded-full bg-pink-400/30" />
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="bg-primary  flex justify-between items-center">
            <div>
              <div className="grid md:grid-cols-3 gap-8">
                <div>
                  <h3 className="text-white  text-[25px] poppins-medium mb-6">
                    Quick Link
                  </h3>
                  <nav className="space-y-4">
                    <Link
                      to="/work"
                      className="block poppins-regular text-gray-400 hover:text-yellow-500 transition-colors"
                    >
                      Services
                    </Link>
                    <Link
                      to="/about"
                      className="block poppins-regular  text-gray-400 hover:text-yellow-500 transition-colors"
                    >
                      About Us
                    </Link>
                    <Link
                      to="/lets-talk"
                      className="block poppins-regular text-gray-400 hover:text-yellow-500 transition-colors"
                    >
                      Contact Us
                    </Link>
                  </nav>
                </div>
                <div>
                  <h3 className="text-white  text-[25px] poppins-medium mb-6">
                    Indian Branch
                  </h3>
                  <div className="space-y-4 mb-8">
                    <p className="block poppins-regular text-gray-400 hover:text-yellow-500 transition-colors">
                      Mohali, Airport Chowk
                    </p>
                    <p className="block poppins-regular text-gray-400 hover:text-yellow-500 transition-colors">
                      Punjab Innovation Centre, 2nd Floor ,202 Office Number
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="text-white  text-[25px] poppins-medium mb-6">
                    Say Hello
                  </h3>
                  <div className="space-y-4 mb-8">
                    <Link
                      to="mailto:admin@example.com"
                      className="block poppins-regular text-gray-400 hover:text-yellow-500 transition-colors"
                    >
                      info@ijhinternational.com
                    </Link>
                    <Link
                      to="tel:+88 02226616146"
                      className="block poppins-regular text-gray-400 hover:text-yellow-500 transition-colors"
                    >
                      +88 02226616146
                    </Link>
                  </div>
                  <div className="flex space-x-4">
                    <Link
                      to="#"
                      className="text-gray-400 hover:text-white transition-colors"
                    >
                      <Facebook className="w-5 h-5 text-gray-400 hover:text-yellow-500 transition-colors" />
                      <span className="sr-only">Facebook</span>
                    </Link>
                    <Link
                      to="#"
                      className="text-gray-400 hover:text-yellow-500 transition-colors"
                    >
                      <Linkedin className="w-5 h-5 text-gray-400 hover:text-yellow-500 transition-colors" />
                      <span className="sr-only">LinkedIn</span>
                    </Link>
                    <Link
                      to="#"
                      className="text-gray-400 hover:text-yellow-500 transition-colors"
                    >
                      <Instagram className="w-5 h-5 text-gray-400 hover:text-yellow-500 transition-colors" />
                      <span className="sr-only">Instagram</span>
                    </Link>
                    <Link
                      to="#"
                      className="text-gray-400 hover:text-yellow-500 transition-colors"
                    >
                      <Twitter className="w-5 h-5 text-gray-400 hover:text-yellow-500 transition-colors" />
                      <span className="sr-only">Twitter</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="pt-8 flex flex-col md:flex-row justify-between items-center">
                <div>
                  <h3 className="text-white  text-[25px] poppins-medium mb-6">
                    More Branchs
                  </h3>
                  <ul className="flex flex-wrap items-center gap-4">
                    <li className="block poppins-regular text-gray-400 hover:text-yellow-500 transition-colors">
                      Saudi Arabia
                    </li>
                    <li className="text-gray-400">|</li>
                    <li className="block poppins-regular text-gray-400 hover:text-yellow-500 transition-colors">
                      UAE
                    </li>
                    <li className="text-gray-400">|</li>
                    <li className="block poppins-regular text-gray-400 hover:text-yellow-500 transition-colors">
                      Qatar
                    </li>
                    <li className="text-gray-400">|</li>
                    <li className="block poppins-regular text-gray-400 hover:text-yellow-500 transition-colors">
                      Bahrain
                    </li>
                    <li className="text-gray-400">|</li>
                    <li className="block poppins-regular text-gray-400 hover:text-yellow-500 transition-colors">
                      Oman
                    </li>
                    <li className="text-gray-400">|</li>
                    <li className="block poppins-regular text-gray-400 hover:text-yellow-500 transition-colors">
                      Bangladesh
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}
