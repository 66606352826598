const BottomFotter = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="bg-yellow-100 py-4 flex justify-center items-center">
      <p className="poppins-regular text-gray-700 text-center text-sm text-bold">
        Copyright © {currentYear} IJH International. All Rights Reserved.
      </p>
    </div>
  );
};

export default BottomFotter;
