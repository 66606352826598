import { Sheet, SheetContent, SheetHeader, SheetTrigger } from "./ui/sheet";
import { Menu } from "lucide-react";
import { Link as ScrollLink } from "react-scroll";
import { ModeToggle } from "./mode-toggle";
import logo from "../assets/images/ijh-logo.png";
const MobileNavigationMenu = () => {
  return (
    <div>
      <Sheet>
        <SheetTrigger asChild>
          <Menu size={24} className="cursor-pointer text-white" />
        </SheetTrigger>
        <SheetContent
          side="right"
          className="flex flex-col justify-center items-center"
        >
          <SheetHeader className="flex justify-center items-center">
            <img src={logo} alt="logo" className="w-fit h-[40px]" />
            <div>
              <h1 className="text-white text-[16px] poppins-medium">
               IJH International
              </h1>
              <h1 className="text-white text-[16px] poppins-medium">RL-2518</h1>
            </div>
          </SheetHeader>
          <nav className="flex flex-col items-center justify-center space-y-4 mt-4 z-10">
            <ScrollLink
              to="/"
              className="px-4 py-2  transition-colors text-base font-medium text-white hover:text-yellow-500 active:text-yellow-500"
            >
              Home
            </ScrollLink>
            <ScrollLink
              to="service"
              className="px-4 py-2  transition-colors text-base font-medium text-white hover:text-yellow-500 active:text-yellow-500"
            >
              Service
            </ScrollLink>
            <ScrollLink
              to="contact"
              className="px-4 py-2  transition-colors text-base font-medium text-white hover:text-yellow-500 active:text-yellow-500"
            >
              Contact Us
            </ScrollLink>
            <ScrollLink
              to="about"
              className="px-4 py-2  transition-colors text-base font-medium text-white hover:text-yellow-500 active:text-yellow-500"
            >
              About Us
            </ScrollLink>
            <ScrollLink
              to="reviews"
              className="px-4 py-2  transition-colors text-base font-medium text-white hover:text-yellow-500 active:text-yellow-500"
            >
              Review
            </ScrollLink>
          </nav>
          <ModeToggle />
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default MobileNavigationMenu;
