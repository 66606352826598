import Container from "../common/Container";
import aboutUsImage from "../../assets/images/about.webp";
const AboutUsSection = () => {
  return (
    <div id="about">
      <Container className="md:pb-32">
        <div className="flex flex-col md:flex-row gap-8">
          <div className="flex-1">
            <h3 className="text-base font-semibold dark:text-white">About Us</h3>
            <p className="text-6xl md:text-5xl lg:text-6xl font-semibold dark:text-white">
              We provide the best <br /> tour facilities.
            </p>
            <p className="text-lg pt-8 pb-12 dark:text-white/75">
              IJH, is a comprehensive inbound and outbound tour operator in
              Dhaka, Bangladesh. Also IJH Recruitment is a boutique recruitment
              firm that specializes in matching the right talent to the right
              job opportunities across world. IJH Recruitment is registered as a
              Human Resources Firm We work on exclusive assignments as we are a
              preferred Recruitment Business Partner for our clients.
            </p>

            <div className="grid gap-4">
              <div className="col-span-12 lg:col-span-6 bg-[#63AB45]/40 hover:bg-[#63AB45]/80 transition-all duration-300 cursor-pointer p-4 rounded-lg">
                <h4 className="text-xl pb-3 font-semibold dark:text-white">Our Mission</h4>
                <p className="text-base dark:text-white/75">
                  Having the right people for the right job“ “we go above and
                  beyond to earn customer satisfaction
                </p>
              </div>
              <div className="col-span-12 lg:col-span-6 bg-[#FBB03B]/40 hover:bg-[#FBB03B]/80 transition-all duration-300 cursor-pointer p-4 rounded-lg">
                <h4 className="text-xl pb-3 font-semibold dark:text-white">Our Value</h4>
                <p className="text-base dark:text-white/75">
                  Adaptable, committed, straightforward, connections
                </p>
              </div>
              <div className="col-span-12 bg-[#FEF9C3]/40 hover:bg-[#FEF9C3]/80 dark:hover:bg-[#FEF9C3]/30 transition-all duration-300 cursor-pointer p-4 rounded-lg">
                <h4 className="text-xl pb-3 font-semibold dark:text-white">Our Vision</h4>
                <p className="text-base dark:text-white/75">
                  Our vision is to provide exceptional travel experience via
                  exciting trips To be a preferred employer and offer an
                  inspirational working environment in which our employees can
                  thrive.
                </p>
              </div>
            </div>
          </div>
          <div className="flex-1 flex justify-center items-center">
            <img
              src={aboutUsImage}
              alt="about us"
              className="object-cover rounded-lg"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AboutUsSection;
