import Marquee from "react-fast-marquee";
import countri1 from "../../assets/images/our-global-network/1.jpg";
import countri2 from "../../assets/images/our-global-network/2.png";
import countri3 from "../../assets/images/our-global-network/3.png";
import countri4 from "../../assets/images/our-global-network/4.png";
import countri5 from "../../assets/images/our-global-network/5.png";
import countri6 from "../../assets/images/our-global-network/6.png";
import countri7 from "../../assets/images/our-global-network/7.jpg";
import countri8 from "../../assets/images/our-global-network/8.png";
import countri9 from "../../assets/images/our-global-network/9.jpg";
import countri10 from "../../assets/images/our-global-network/10.png";
import countri11 from "../../assets/images/our-global-network/11.png";
import countri12 from "../../assets/images/our-global-network/12.png";
import countri13 from "../../assets/images/our-global-network/13.png";
import countri14 from "../../assets/images/our-global-network/14.png";
import countri15 from "../../assets/images/our-global-network/15.png";
import countri16 from "../../assets/images/our-global-network/16.png";
import countri18 from "../../assets/images/our-global-network/18.jpg";
import countri19 from "../../assets/images/our-global-network/19.png";
import countri20 from "../../assets/images/our-global-network/20.png";

const GlobalNetwork = () => {
  return (
    <div className="pb-10">
      <Marquee className="flex items-center justify-center gap-14 cursor-pointer" delay={5} pauseOnHover={true} >
        <img src={countri1} alt="country" className="w-full h-20" />
        <img src={countri2} alt="country" className="w-full h-20" />
        <img src={countri3} alt="country" className="w-full h-20" />
        <img src={countri4} alt="country" className="w-full h-20" />
        <img src={countri5} alt="country" className="w-full h-20" />
        <img src={countri6} alt="country" className="w-full h-20" />
        <img src={countri7} alt="country" className="w-full h-20" />
        <img src={countri8} alt="country" className="w-full h-20" />
        <img src={countri9} alt="country" className="w-full h-20" />
        <img src={countri10} alt="country" className="w-full h-20" />
        <img src={countri11} alt="country" className="w-full h-20" />
        <img src={countri12} alt="country" className="w-full h-20" />
        <img src={countri13} alt="country" className="w-full h-20" />
        <img src={countri14} alt="country" className="w-full h-20" />
        <img src={countri15} alt="country" className="w-full h-20" />
        <img src={countri16} alt="country" className="w-full h-20" />
        <img src={countri18} alt="country" className="w-full h-20" />
        <img src={countri19} alt="country" className="w-full h-20" />
        <img src={countri20} alt="country" className="w-full h-20" />
      </Marquee>
    </div>
  );
};

export default GlobalNetwork;
