import Container from "../common/Container";
import { Testimonial } from "./Testimonial";

export function ReviewSection() {
  return (
    <div id="reviews">
      <Container  className="py-12 md:py-32">
        <div>
          <h3 className="text-base font-semibold dark:text-white">Reviews</h3>
          <p className="text-6xl md:text-5xl lg:text-6xl font-semibold dark:text-white">
            What Client Say About us
          </p>
        </div>

        <div>
          <Testimonial />
        </div>
      </Container>
    </div>
  );
}
