import { Service } from "../types/Service";

const services: Service[] = [
  {
    title: "HOLIDAY",
    description: "Book super cheap all inclusive holidays & packages around the world. Our expert team is always ready to help you find your best destination within your budget.",
    image: "https://cdn.pixabay.com/photo/2019/07/12/19/13/happy-4333439_1280.jpg"
  },
  {
    title: "VISA",
    description: "Do you need a visa for your travel? No more worries. Just call us. Our expert visa team will guide you to obtain your visa and make your trip happen.",
    image: "https://cdn.pixabay.com/photo/2017/08/14/22/05/passport-2642170_640.jpg"
  },
  {
    title: "FLIGHT",
    description: "Find cheap flights with NCL Tours. Compare and book discount flight tickets from over 400 standard and budget airlines. Search top flight deals for your next trip.",
    image: "https://cdn.pixabay.com/photo/2023/07/24/01/31/plane-8145957_1280.jpg"
  },
  {
    title: "CRUISE",
    description: "Luxurious cruises on the Atlantic, Pacific, and Indian oceans. Party with casino, bar, buffet, swimming pool, or even golf course & parks. Book your dream tour with us.",
    image: "https://cdn.pixabay.com/photo/2021/03/06/11/28/ships-6073537_640.jpg"
  },
  {
    title: "UMRAH",
    description: "We are providing Umrah with visa, ticket, transport & meal. Most cheapest price from other companies.",
    image: "https://cdn.pixabay.com/photo/2018/10/30/03/05/islam-3782623_640.jpg"
  },
  {
    title: "TRANSPORT",
    description: "International coach, train, mini bus, or private taxi transports. Quick and easy booking process. Just talk to one of our team members or book online.",
    image: "https://cdn.pixabay.com/photo/2017/04/04/22/27/trolley-2203329_640.jpg"
  }
];

export default services;