import { Link } from "react-router-dom";
import Container from "./Container";
import { MainNavigationMenu } from "../NavigationMenu";
import MobileNavigationMenu from "../MobileNavigationMenu";
import { ModeToggle } from "../mode-toggle";
import logo from "../../assets/images/ijh-logo.png";

const Header = () => {
  return (
    <Container>
      <div className="flex h-20 items-center justify-between bg-primary px-8 rounded-lg">
        {/* Logo */}
        <Link to="/" className="flex items-center space-x-2">
          <img src={logo} alt="IJH Logo" className="w-fit h-[40px]" />
          <div>
            <h1 className="text-white text-[16px] poppins-medium">
             IJH International
            </h1>
            <h1 className="text-white text-[16px] poppins-medium">RL-2518</h1>
          </div>
        </Link>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex lg:gap-x-8">
          <MainNavigationMenu />
          <ModeToggle />
        </div>

        {/* Mobile Menu Button */}
        <div className="lg:hidden">
          <MobileNavigationMenu />
        </div>
      </div>
    </Container>
  );
};

export default Header;
