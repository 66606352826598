import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import ServiceCard from "./ServiceCard";
import services from "../../data/service";
import "swiper/css";
import "swiper/css/navigation";
import "./OurService.css";
import Container from "../common/Container";
import { flags } from "../../data/flag";
import { AnimatedTooltip } from "../ui/animated-tooltip";

const OurService = () => {
  return (
    <div className="py-10" id="service">
      <Container>
        <h2 className="text-6xl md:text-5xl lg:text-6xl text-center dark:text-white font-bold mb-4">
          Our Services
        </h2>
      </Container>
      <Swiper
        navigation={true}
        modules={[Navigation, Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        className="mySwiper"
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
      >
        {services.map((service) => (
          <SwiperSlide key={service.title}>
            <ServiceCard service={service} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Container>
        <h2 className="text-xl md:text-2xl lg:text-3xl text-center dark:text-white font-bold mb-4">
          Country of Visa Service
        </h2>
      </Container>
      <div className="flex justify-center">
        <AnimatedTooltip items={flags} />
      </div>
    </div>
  );
};

export default OurService;
